@media (min-width: 768px) {
  .HLP-hero .HLP-hero-spa1 {
    max-width: 300px;
  }
  .HLP-hero .HLP-hero-spa3 {
    max-width: 310px;
  }
}

.HLP-block:nth-child(2n + 1) {
  background-color: var(--colour-background-015);
  background-image: none;
}

.HLP-block:nth-child(2n) {
  background-color: var(--colour-background-010);
}

.ActionCardTemplate-text > span {
  margin-left: calc(var(--base-unit) / 2);
  margin-right: calc(var(--base-unit) / 2);
}
